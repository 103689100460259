<template>
  <router-view class="bg-transparent-51 mt-4"></router-view>
</template>

<script>
export default {
  name: "my-vue",
  components: {},
};
</script>

<style></style>
